<template>

  <div class="checkout-items">
    <b-card v-if="products.length === 0">
      <b-card-body
        class="bold"
      >
        Brak produktów w koszyku. Przejdź do <b-link to="/sklep">
          sklepu
        </b-link> aby wybrać produkty.
      </b-card-body>
    </b-card>

    <b-card
      v-for="product in products"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >

      <!-- Product Image -->
      <div class="item-img">
        <b-link :href="`/sklep/${product.course.slug}`">
          <b-img
            :src="`${product.thumbnail}`"
            :alt="`${product.name}-${product.id}`"
          />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-1">
            <b-link
              class="text-body"
              :href="`/sklep/${product.course.slug}`"
            >
              {{ product.course.title }}
            </b-link>
          </h6>
          <span class="item-company">{{ product.course.short_description }}</span>
        </div>
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
             <span v-if="(product.course.price*0.01).toFixed(2) !== product.total.toFixed(2)" class="text-muted">
               <del>{{ (product.course.price*0.01).toFixed(2) }}</del>
             </span>
              {{ product.total.toFixed(2) }} zł
            </h4>
          </div>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeProductFromCartClick(product)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>Usuń</span>
        </b-button>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BBadge, BButton, BCard, BCardBody, BFormSpinbutton, BImg, BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useEcommerce, useEcommerceUi } from '@/views/apps/e-commerce/useEcommerce'
import store from '@/store'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
  },
  setup(props, context) {
    const products = ref([])
    const cart = ref({})

    const { toggleProductInWishlist } = useEcommerceUi()
    const { removeProductFromCart } = useEcommerce()
    const removeProductFromCartClick = product => {
      removeProductFromCart(product.id)
        .then(() => {
            const productIndex = products.value.findIndex(p => p.id === product.id)
            products.value.splice(productIndex, 1)

            store.dispatch("app-ecommerce/fetchCartItems");
            context.emit('cart', 'recalculate')
          })

    }

    const fetchCartProducts = () => {
      store.dispatch('app-ecommerce/fetchCartProducts')
        .then(response => {
          products.value = response.data.items
        })
    }
    fetchCartProducts()

    return {
      products,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,

      // Filter
      formatDate,
    }
  },
}
</script>
