<template>
  <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
  >
    <b-form
        class="list-view product-checkout"
        @submit.prevent="handleSubmit(onSubmit)"
    >

      <!-- Left Form -->
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title>Dodaj adres</b-card-title>
          <b-card-text class="text-muted mt-25">
            Miej pewność, że adres jest poprawny
          </b-card-text>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Imię"
                  rules="required|min:3"
              >
                <b-form-group
                    label="Imię"
                    label-for="first_name"
                    class="mb-2"
                >
                  <b-form-input
                      id="first-name"
                      v-model="addressDetails.first_name"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Nazwisko"
                  rules="required|min:5"
              >
                <b-form-group
                    label="Nazwisko"
                    label-for="last_name"
                    class="mb-2"
                >
                  <b-form-input
                      id="last_name"
                      v-model="addressDetails.last_name"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Adres"
                  rules="required|min:5"
              >
                <b-form-group
                    label="Adres"
                    label-for="address"
                    class="mb-2"
                >
                  <b-form-input
                      id="addresse"
                      v-model="addressDetails.address"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-group class="d-flex flex-row">
                    <b-form-input
                        id="address_house"
                        v-model="addressDetails.address_house"
                        class="w-50 d-inline"
                        placeholder="dom"
                    ></b-form-input>
                    <b-form-input
                        id="address_flat"
                        class="w-50 d-inline"
                        placeholder="mieszkanie"
                        v-model="addressDetails.address_flat"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Adres cd"
              >
                <b-form-group
                    label="Adres cd"
                    label-for="address_2"
                    class="mb-2"
                >
                  <b-form-input
                      id="address_2"
                      v-model="addressDetails.address_2"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-group class="d-flex flex-row">
                    <b-form-input
                      id="address_2_house"
                      v-model="addressDetails.address_2_house"
                      class="w-50 d-inline"
                      placeholder="dom"
                    ></b-form-input>
                    <b-form-input
                    id="address_2_flat"
                    class="w-50 d-inline"
                    placeholder="mieszkanie"
                    v-model="addressDetails.address_2_flat"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Adres invoice"
              >
                <b-form-group
                    label="Adres invoice"
                    label-for="address_invoice"
                    class="mb-2"
                >
                  <b-form-input
                      id="address_invoice"
                      v-model="addressDetails.address_invoice"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-group class="d-flex flex-row">
                    <b-form-input
                        id="address_invoice_house"
                        v-model="addressDetails.address_invoice_house"
                        class="w-50 d-inline"
                        placeholder="dom"
                    ></b-form-input>
                    <b-form-input
                        id="address_invoice_flat"
                        class="w-50 d-inline"
                        placeholder="mieszkanie"
                        v-model="addressDetails.address_invoice_flat"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- pincode -->
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Kod pocztowy"
                  rules="required|min:5"
              >
                <b-form-group
                    label="Kod pocztowy"
                    label-for="post_code"
                    class="mb-2"
                >
                  <cleave
                      id="post_code"
                      v-model="addressDetails.post_code"
                      :state="getValidationState(validationContext)"
                      :options="options.postcode"
                      class="form-control"
                      :raw="false"
                      maxlength="6"
                      minlength="6"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>



            <!-- City -->
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Miasto"
                  rules="required|min:3"
              >
                <b-form-group
                    label="Miasto"
                    label-for="city"
                    class="mb-2"
                >
                  <b-form-input
                      id="city"
                      v-model="addressDetails.city"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- state -->
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Kraj"
                  rules="required|min:5"
              >
                <b-form-group
                    label="Kraj"
                    label-for="country"
                    class="mb-2"
                >
                  <b-form-input
                      id="country"
                      v-model="addressDetails.country"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
                cols="12"
                md="6"
                v-if="showExamYear"
            >
              <validation-provider
                  #default="validationContext"
                  name="W którym roku zdajesz maturę?"
                  rules="required|min:4"
              >
                <b-form-group
                    label="W którym roku zdajesz maturę?"
                    label-for="country"
                    class="mb-2"
                >

                  <b-form-select
                      id="exam_year"
                      v-model="addressDetails.exam_year"
                      :state="getValidationState(validationContext)"
                      :options="examYears"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- COMMENT FIELD -->
          <!--          <hr>-->
          <!--          <b-row>-->
          <!--            <b-col-->
          <!--                cols="12"-->
          <!--                md="12"-->
          <!--            >-->
          <!--              <validation-provider-->
          <!--                  #default="validationContext"-->
          <!--                  name="Komentarz"-->
          <!--                  rules=""-->
          <!--              >-->
          <!--                <b-form-group-->
          <!--                    label="Komentarz"-->
          <!--                    label-for="comments"-->
          <!--                    class="mb-2"-->
          <!--                >-->
          <!--                  <b-form-textarea-->
          <!--                      id="comments"-->
          <!--                      v-model="addressDetails.comments"-->
          <!--                      :state="getValidationState(validationContext)"-->
          <!--                      trim-->
          <!--                      placeholder="Komentarz do zamówienia"-->
          <!--                  />-->
          <!--                  <b-form-invalid-feedback>-->
          <!--                    {{ validationContext.errors[0] }}-->
          <!--                  </b-form-invalid-feedback>-->
          <!--                </b-form-group>-->
          <!--              </validation-provider>-->
          <!--            </b-col>-->
          <!--          </b-row>-->

          <hr>
          <b-card-text class="text-muted mt-25">
            <b-form-checkbox
                id="checkbox-1"
                v-model="addressDetails.is_invoice"
                name="checkbox-1"
                :value="addressDetails.is_invoice"
                :unchecked-value="addressDetails.is_invoice"
                :disabled="!total"
            >
              Zaznacz, jeżeli chcesz otrzymać fakturę lub rachunek na osobę fizyczną.
            </b-form-checkbox>
          </b-card-text>
          <b-row>
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Nazwa firmy / Imię nazwisko"
                  rules=""
              >
                <b-form-group
                    label="Nazwa firmy / Imię nazwisko"
                    label-for="company_name"
                    class="mb-2"
                >
                  <b-form-input
                      id="company_name"
                      v-model="addressDetails.company_name"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="NIP"
                  rules=""
              >
                <b-form-group
                    label="NIP"
                    label-for="nip"
                    class="mb-2"
                >
                  <b-form-input
                      id="nip"
                      v-model="addressDetails.nip"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Right Col -->
      <div class="customer-card">
        <b-card title="Płatność">
          <b-card-text class="mb-2">
            Aby dokonać płatności zostaniesz przekierowany do bezpiecznego serwisu PayU.
          </b-card-text>
          <div class="price-details">
            <b-card-text>
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title mb-1">
                    Administratorem danych osobowych jest ChemMaster Sonia Radosz. Twoje dane osobowe są przetwarzane
                    zgodnie z ustawą z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz.U.2016.922 j.t.).
                  </div>
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    <validation-provider
                        #default="validationContext"
                        name="regulations"
                        rules="required"
                    >
                      <b-form-checkbox
                          v-model="reg"
                          :state="getValidationState(validationContext)"
                      >
                        <small class="font-weight-bolder">Przeczytałem/am i akceptuję
                          <router-link to="/regulamin">regulamin</router-link>
                          oraz
                          <router-link to="/polityka-prywatnosci">politykę prywatności *</router-link>
                        </small>
                      </b-form-checkbox>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    <validation-provider
                        #default="validationContext"
                        name="regulations2"
                        rules="required"
                    >
                      <b-form-checkbox
                          v-model="reg2"
                          :state="getValidationState(validationContext)"
                      >
                        <small class="font-weight-bolder">Oświadczam, że wyrażam zgodę na rozpoczęcie świadczenia przed
                          upływem terminu do
                          odstąpienia od umowy oraz przyjmuję do wiadomości, że z chwilą rozpoczęcia świadczenia utracę
                          prawo do odstąpienia od umowy oraz że po rozpoczęciu świadczenia nie będzie możliwości zmiany
                          kursu oraz oświadczam, że podane dane osobowe są prawdziwe *</small>
                        <br/>
                        <small>Ich udostępnienie jest wymagane do prawidłowego wykonania zlecenia oraz do celów
                          rozliczeń przy płatnościach internetowych.</small>
                      </b-form-checkbox>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    <validation-provider
                        #default="validationContext"
                        name="regulations4"
                        :rules="isRequireNewsletter ? 'required' : ''"
                    >
                      <b-form-checkbox
                          v-model="reg4"
                          :state="getValidationState(validationContext)"
                      >
                        <small class="font-weight-bolder">Wyrażam zgodę na przetwarzanie danych osobowych oraz
                          otrzymywanie newslettera i informacji
                          marketingowych drogą e-mailową od Administratora danych: Sonia Radosz, prowadzącą działalność
                          gospodarczą pod firmą „ChemMaster Sonia Radosz” pod adresem: ul. Wodzisławska 4,
                          44-240 Żory, NIP: 6381739715. Szczegóły dotyczące przetwarzania danych znajdziesz w
                          <router-link to="/polityka-prywatnosci">Polityce Prywatności</router-link>
                          .</small>
                      </b-form-checkbox>
                      <div class="alert alert-danger p-2 mt-1" v-show="!getValidationState(validationContext)">
                        Przy zakupie darmowego kursu zgoda marketingowa jest wymagana.
                      </div>
                    </validation-provider>
                  </div>
                </li>
              </ul>
            </b-card-text>
          </div>
          <b-button
              variant="primary"
              block
              type="submit"
              @click="scrollToError"
          >
            Przejdź do Płatności
          </b-button>

        </b-card>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Polish from 'vee-validate/dist/locale/pl'

import vSelect from 'vue-select'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import { useEcommerce } from '@/views/apps/e-commerce/useEcommerce'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    BFormCheckbox,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // 3rd party
    vSelect,
  },
  props: {
    addressDetails: {
      type: Object,
      required: true,
    },
    reg: {
      type: Object,
      required: true,
    },
    reg2: {
      type: Object,
      required: true,
    },
    reg4: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      locale: 'pl',
      total: 0,
      products: [],
      examYears: ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029'],
      options: {
        postcode: {
          delimiter: '-',
          blocks: [2, 3],
          uppercase: true,
        },
      }
    }
  },
  methods: {
    getCart() {
      store.dispatch('app-ecommerce/fetchCartProducts')
          .then(response => {
            this.total = response.data.total
            this.products = response.data.items
          })
    },
    scrollToError() {
      setTimeout(function() {
        const el = document.querySelector(".is-invalid:first-of-type");
        el.scrollIntoView();
      },300);
    }
  },
  computed: {
    isRequireNewsletter() {
      let isRequire = false
      this.products.forEach(i => {
        if (i.course.require_newsletter) {
          isRequire = true
        }
      })
      return isRequire
    },
    showExamYear()
    {
      let showExamYear = false;
      this.products.forEach(i => {
        if(i.course.category_id !== 1) {
          showExamYear = true;
        }
      })
      return showExamYear
    }
  },
  created() {
    localize(this.locale, {
      messages: Polish.messages,
      attributes: {
        email: 'E-mail',
        password: 'Hasło',
      },
    })
    this.getCart()
  },
  setup(props, context) {
    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(() => {
      window.scrollTo(0, 0)
    })
    const { addAddressInCart } = useEcommerce()
    const onSubmit = () => {
      addAddressInCart(props.addressDetails)
          .then(response => {
            localStorage.removeItem('cartToken')
            window.location = response.data.redirectUrl
          })
          .catch(error => {
            // if email not confirmed
            if(error.response.status === 403) {
              this.$bvToast.toast(error.response.data.message, {
                title: 'e-mail nie potwierdzony',
                solid: true,
                variant: 'danger',
              });
              this.$router.push('email/verify');
            }
            else if (error.response.data.message) {
              alert(error.response.data.message);
            }
          })
    }

    return {
      // Form
      onSubmit,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      // Form Validators
      required,
      integer,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
* {
  box-sizing: border-box;
}
small {
  font-size: 10px;
}
</style>
