<template>
  <div class="list-view product-checkout mt-0">

    <!-- Products List -->
    <e-commerce-checkout-step-cart-products :key="componentKey" @cart="getCartData" />

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>
        <div class="price-details">
          <h6 class="price-title">
            Szczegóły
          </h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">
                Suma
              </div>
              <div class="detail-amt font-weight-bolder">
                <del v-if="getTotalBeforeDiscount !== total.toFixed(2)">{{ getTotalBeforeDiscount }}zł</del>
                {{ total.toFixed(2) }} zł
              </div>
            </li>
          </ul>

          <b-button
            variant="primary"
            block
            @click="nextStepClick()"
          >
            Zapłać
          </b-button>
        </div>

      </b-card>
      <b-card>
        <b-form-group
            id="fieldset-horizontal2"
            label-cols-sm="12"
            label-cols-lg="12"
            content-cols-sm
            content-cols-lg="12"
            label="Kod rabatowy"
            label-for="input-horizontal"
        >
          <b-form-input
              v-model="code"
              placeholder="Kod rabatowy"
          />
        </b-form-group>
        <b-button
            variant="primary"
            block
            @click="discountAssign()"
        >
          Zastosuj kod
        </b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton, BCard, BFormFile, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import { isUserLoggedIn } from '@/auth/utils'
import router from '@/router'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BFormInput,
    BFormGroup,
    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  data() {
    return {
      total: 0,
      vat: 0,
      subtotal: 0,
      products: [],
      code: "",
      componentKey: 0,
    }
  },
  computed: {
    getTotalBeforeDiscount()
    {
      let sum = 0
      this.products.forEach(i => {
        sum += (i.course.price*0.01)
      })
      return sum.toFixed(2)
    }
  },
  created() {
    this.getCart()
  },
  methods: {
    getCart() {
      store.dispatch('app-ecommerce/fetchCartProducts')
        .then(response => {
          this.total = response.data.total
          this.vat = response.data.vat
          this.subtotal = response.data.subtotal
          this.products = response.data.items
        })
    },
    getCartData(value) {
      this.getCart()
    },
    nextStepClick() {
      if (!isUserLoggedIn()) {
        router.push({ name: 'register' })
      } else {
        this.$emit('next-step')
        window.scrollTo(0, 0)
      }
    },
    discountAssign() {
      store.dispatch('app-ecommerce/addDiscountToCart', {code: this.code})
      .then(response => {
        if (response.status === 200) {
          this.code = "";
          this.getCartData()
          context.emit('cart', 'recalculate')
          this.componentKey += 1;
          context.$forceUpdate()
        }
      })
    }
  },
}
</script>
